export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  StreamRequest: { input: any; output: any; }
  BigInt: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  actors: Array<Actor>;
  admin?: Maybe<Admin>;
  ancestorFolders?: Maybe<Array<AncestorFolder>>;
  avatarTags: Array<AvatarTaggingAttribute>;
  calculateTax: TaxCalculation;
  contentTiles: Array<ContentTile>;
  entitlements: Array<Entitlement>;
  features: Array<Feature>;
  folder?: Maybe<Folder>;
  respellingSuggestions: Array<RespellingSuggestion>;
  sharedProject?: Maybe<Project>;
  subscriptionPlans: Array<SubscriptionPlan>;
  tutorials: Array<Tutorial>;
  tutorials_v2: Array<Tutorial_V2>;
  user: User;
  userInvitation?: Maybe<UserInvitation>;
};


export type QueryAncestorFoldersArgs = {
  inputs: GetFoldersInputs;
};


export type QueryCalculateTaxArgs = {
  inputs: CalculateTaxInputs;
};


export type QueryFolderArgs = {
  inputs: GetFoldersInputs;
};


export type QueryRespellingSuggestionsArgs = {
  inputs: RespellingSuggestionsInputs;
};


export type QuerySharedProjectArgs = {
  inputs: SharedProjectInputs;
};


export type QueryUserInvitationArgs = {
  inputs: UserInvitationInputs;
};

export type Actor = {
  __typename?: 'Actor';
  accessLevel?: Maybe<ActorAccessLevel>;
  availableIn?: Maybe<SubscriptionTier>;
  description: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  initials: Scalars['String']['output'];
  isCustomActor?: Maybe<Scalars['Boolean']['output']>;
  locale?: Maybe<Locale>;
  name: Scalars['String']['output'];
  preview: Scalars['String']['output'];
  previewText: Scalars['String']['output'];
  /** Indicates possible subscription upgrades in order to access actor. Relates to availableIn field. */
  restrictionTags?: Maybe<Array<SubscriptionTier>>;
  tags?: Maybe<Array<AvatarTaggingAttribute>>;
  tldSampleSrc?: Maybe<Scalars['String']['output']>;
  tldSampleText?: Maybe<Scalars['String']['output']>;
  variants: Array<ActorVariant>;
};

export enum ActorAccessLevel {
  Locked = 'LOCKED',
  Read = 'READ'
}

export enum SubscriptionTier {
  Creative = 'Creative',
  Designer = 'Designer',
  DesignerTrial = 'DesignerTrial',
  Maker = 'Maker',
  Producer = 'Producer',
  Team = 'Team',
  TeamEnterprise = 'TeamEnterprise',
  Trial = 'Trial'
}

export enum Locale {
  Au = 'AU',
  Br = 'BR',
  Ca = 'CA',
  De = 'DE',
  Fr = 'FR',
  Gb = 'GB',
  Ie = 'IE',
  Mx = 'MX',
  Uk = 'UK',
  Us = 'US',
  Za = 'ZA'
}

export type AvatarTaggingAttribute = {
  __typename?: 'AvatarTaggingAttribute';
  attribute: Scalars['String']['output'];
  category: AvatarTaggingAttributeCategoryEnum;
  internalTag: Scalars['Boolean']['output'];
};

export enum AvatarTaggingAttributeCategoryEnum {
  Beta = 'BETA',
  Custom = 'CUSTOM',
  Featured = 'FEATURED',
  New = 'NEW',
  PerformanceStyles = 'PERFORMANCE_STYLES',
  Recommended = 'RECOMMENDED',
  RegionalAccents = 'REGIONAL_ACCENTS',
  VoiceCharacteristics = 'VOICE_CHARACTERISTICS'
}

export type ActorVariant = {
  __typename?: 'ActorVariant';
  id: Scalars['Int']['output'];
  /** An archived variant will no longer show up in-studio */
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isBeta?: Maybe<Scalars['Boolean']['output']>;
  locale: Scalars['String']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<AvatarTaggingAttribute>>;
  teamSettings?: Maybe<TeamAvatarSettings>;
  userSettings?: Maybe<UserAvatarSettings>;
  variantType: ActorVariantType;
  /** Will always be equivalent to the latest model version */
  version: Scalars['String']['output'];
  /** A map of all the model versions available for a variant. These will frequently be updated as new versions of the model are released. */
  versions: ActorVariantVersions;
};

export type TeamAvatarSettings = {
  __typename?: 'TeamAvatarSettings';
  favoritedAt?: Maybe<Scalars['String']['output']>;
};

export type UserAvatarSettings = {
  __typename?: 'UserAvatarSettings';
  favoritedAt?: Maybe<Scalars['String']['output']>;
  hiddenAt?: Maybe<Scalars['String']['output']>;
};

export type ActorVariantType = {
  __typename?: 'ActorVariantType';
  description?: Maybe<Scalars['String']['output']>;
  id: ActorVariantTypeId;
  label: Scalars['String']['output'];
};

export enum ActorVariantTypeId {
  Casual = 'CASUAL',
  Character = 'CHARACTER',
  Conversational = 'CONVERSATIONAL',
  Custom = 'CUSTOM',
  CustomV2 = 'CUSTOM_V2',
  Narration = 'NARRATION',
  Promo = 'PROMO'
}

export type ActorVariantVersions = {
  __typename?: 'ActorVariantVersions';
  latest: TtsModelRelease;
  next?: Maybe<TtsModelRelease>;
  previous?: Maybe<TtsModelRelease>;
};

export type TtsModelRelease = {
  __typename?: 'TTSModelRelease';
  description?: Maybe<Scalars['String']['output']>;
  features: Array<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  model: Scalars['String']['output'];
  overrideSpeakerVariantId?: Maybe<Scalars['Int']['output']>;
  studioProjectVersion?: Maybe<StudioProjectVersion>;
};

export enum StudioProjectVersion {
  AzureFrench = 'azure_french',
  AzureGerman = 'azure_german',
  AzureItalian = 'azure_italian',
  AzurePortuguese = 'azure_portuguese',
  AzureSpanish = 'azure_spanish',
  ExtAdobe = 'ext_adobe',
  ExtCanva = 'ext_canva',
  Legacy = 'legacy',
  V2 = 'v2'
}

export type Admin = {
  __typename?: 'Admin';
  team?: Maybe<Team>;
  teams: AdminTeamsPaginatedResponse;
  userSearch?: Maybe<User>;
};


export type AdminTeamArgs = {
  inputs: AdminTeamInputs;
};


export type AdminTeamsArgs = {
  inputs?: InputMaybe<PaginatedInputs>;
};


export type AdminUserSearchArgs = {
  inputs?: InputMaybe<AdminUserSearchInputs>;
};

export type AdminTeamInputs = {
  teamId: Scalars['String']['input'];
};

export type Team = {
  __typename?: 'Team';
  companyName?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitations: Array<UserInvitation>;
  managed: Scalars['Boolean']['output'];
  memberCount: Scalars['Int']['output'];
  members: Array<TeamMember>;
  productAccessLimits: ProductAccessLimits;
  productAccessPeriodUsage: ProductAccessPeriodUsage;
  ssoConnectionName?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<UserSubscription>;
  viewersRoleOnTeam?: Maybe<TeamRole>;
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitationCode: Scalars['String']['output'];
  invitationStatus: UserInvitationStatus;
  lastName?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']['output']>;
  teamRole?: Maybe<TeamRole>;
  updatedAt: Scalars['String']['output'];
};

export enum UserInvitationStatus {
  Accepted = 'accepted',
  Expired = 'expired',
  Pending = 'pending'
}

export enum TeamRole {
  Admin = 'admin',
  Owner = 'owner',
  Producer = 'producer'
}

export type TeamMember = {
  __typename?: 'TeamMember';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  name: Scalars['String']['output'];
  role: TeamRole;
};

export type ProductAccessLimits = {
  __typename?: 'ProductAccessLimits';
  clipGenerationLimit: Scalars['Int']['output'];
  editorCharacterLimit: Scalars['Int']['output'];
  projectsLimit: Scalars['Int']['output'];
};

export type ProductAccessPeriodUsage = {
  __typename?: 'ProductAccessPeriodUsage';
  clipsCredited?: Maybe<Scalars['Int']['output']>;
  clipsGenerated: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  joinedAt: Scalars['String']['output'];
  lifetimeCharactersRendered: Scalars['Int']['output'];
  periodEnd: Scalars['Int']['output'];
  periodStart: Scalars['Int']['output'];
  projects: Scalars['Int']['output'];
  usageScope: ProductAccessUsageScope;
};

export enum ProductAccessUsageScope {
  Team = 'Team',
  User = 'User'
}

export type UserSubscription = {
  __typename?: 'UserSubscription';
  billing?: Maybe<SubscriptionBilling>;
  billingHistory?: Maybe<Array<BillingHistory>>;
  cancelAt?: Maybe<Scalars['Int']['output']>;
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['Int']['output']>;
  churnkeyAuthHash?: Maybe<Scalars['String']['output']>;
  created: Scalars['Int']['output'];
  currentPeriodEnd: Scalars['Int']['output'];
  currentPeriodStart: Scalars['Int']['output'];
  customerId: Scalars['ID']['output'];
  discount?: Maybe<SubscriptionDiscount>;
  id: Scalars['ID']['output'];
  isTeamSubscription: Scalars['Boolean']['output'];
  plan: SubscriptionPlan;
  seatCount: Scalars['Int']['output'];
  status: SubscriptionStatus;
};

export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  addressInfo?: Maybe<AddressInfo>;
  source?: Maybe<BillingSource>;
};

export type AddressInfo = {
  __typename?: 'AddressInfo';
  shipping?: Maybe<Shipping>;
  taxId?: Maybe<TaxId>;
};

export type Shipping = {
  __typename?: 'Shipping';
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type TaxId = {
  __typename?: 'TaxId';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type BillingSource = {
  __typename?: 'BillingSource';
  brand: Scalars['String']['output'];
  expiry: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BillingHistory = {
  __typename?: 'BillingHistory';
  date: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** if invoice has not been finalized, pdf will be null; undefined for subscriptions */
  pdf?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  type: BillingHistoryType;
};

export enum BillingHistoryType {
  Invoice = 'INVOICE'
}

export type SubscriptionDiscount = {
  __typename?: 'SubscriptionDiscount';
  coupon?: Maybe<SubscriptionCoupon>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubscriptionCoupon = {
  __typename?: 'SubscriptionCoupon';
  amount_off?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  percent_off?: Maybe<Scalars['Int']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  accessLimits: ProductAccessLimits;
  active: Scalars['Boolean']['output'];
  amount: Scalars['Int']['output'];
  auth0Role?: Maybe<Role>;
  auth0RoleId: Scalars['ID']['output'];
  currency: Scalars['String']['output'];
  discount?: Maybe<Array<Discount>>;
  displayInformation: SubscriptionPlanDisplayInformation;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: SubscriptionInterval;
  intervalCount: Scalars['Int']['output'];
  productId: Scalars['ID']['output'];
  selectable: Scalars['Boolean']['output'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Discount = {
  __typename?: 'Discount';
  amount: Scalars['Int']['output'];
  coupon?: Maybe<Coupon>;
};

export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionPlanDisplayInformation = {
  __typename?: 'SubscriptionPlanDisplayInformation';
  badgeLabel?: Maybe<Scalars['String']['output']>;
  discountLabel?: Maybe<Scalars['String']['output']>;
  displayActorCount: Scalars['Int']['output'];
  displayActorVariantCount: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  displayPrice: Scalars['String']['output'];
  displaySubheading: Scalars['String']['output'];
  displayTertiaryHeading: Scalars['String']['output'];
  lineItems: Array<SubscriptionPlanLineItems>;
  lineItemsHeader?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanLineItems = {
  __typename?: 'SubscriptionPlanLineItems';
  emphasisLabel?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export enum SubscriptionInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  TrialExpired = 'TRIAL_EXPIRED',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export type PaginatedInputs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderBy {
  AlphabeticalAsc = 'alphabetical_ASC',
  AlphabeticalDesc = 'alphabetical_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AdminTeamsPaginatedResponse = PaginatedResponse & {
  __typename?: 'AdminTeamsPaginatedResponse';
  currentPage: Scalars['Int']['output'];
  itemCount: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  orderBy: OrderBy;
  pageCount: Scalars['Int']['output'];
  searchInput?: Maybe<Scalars['String']['output']>;
  teams: Array<Team>;
};

export type PaginatedResponse = {
  currentPage: Scalars['Int']['output'];
  /** Total number of items, regardless of pagination */
  itemCount: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  orderBy: OrderBy;
  /** Total number of pages available to query */
  pageCount: Scalars['Int']['output'];
  searchInput?: Maybe<Scalars['String']['output']>;
};

export type AdminUserSearchInputs = {
  email?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  abilities: Scalars['String']['output'];
  /** FilterAndSearchAvatarsInputs is optional because excluding it returns all Actors */
  actors: Array<Actor>;
  announcements: Array<Announcement>;
  apiCharacterUsage?: Maybe<Scalars['Int']['output']>;
  apiSubscriptions: Array<Maybe<ApiSubscription>>;
  billingHistory: Array<BillingHistory>;
  clipsGenerated: Scalars['Int']['output'];
  clipsLimit: Scalars['Int']['output'];
  clipsMetrics: ClipsMetrics;
  contact?: Maybe<UserContact>;
  contentModerationAllowlist?: Maybe<Scalars['Boolean']['output']>;
  contentModerationBlock?: Maybe<Scalars['Boolean']['output']>;
  contentModerationFlaggedContent?: Maybe<Array<ContentModerationFlaggedContent>>;
  /** @deprecated Use contentModerationAllowlist */
  contentModerationWhitelist?: Maybe<Scalars['Boolean']['output']>;
  drives?: Maybe<Array<UserDrive>>;
  flags: Array<Scalars['String']['output']>;
  folders?: Maybe<Array<Folder>>;
  hasAcceptedTerms: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isCustomVoiceOnlySubscription?: Maybe<Scalars['Boolean']['output']>;
  isVerified: Scalars['Boolean']['output'];
  pendingTeamInvitation?: Maybe<UserInvitation>;
  permissions: Array<Scalars['String']['output']>;
  personalProjects: ProjectsConnection;
  phoneticLibrary: PhoneticLibrary;
  productAccessLimits: ProductAccessLimits;
  productAccessPeriodUsage: ProductAccessPeriodUsage;
  profile: Profile;
  project?: Maybe<Project>;
  roles: Array<Role>;
  selections: Array<UserSelectionType>;
  settings: UserSettings;
  sharedProjects: ProjectsConnection;
  /** @deprecated Use studioProjectVersions */
  studioProjectTypes: Array<StudioProjectType>;
  studioProjectVersions: Array<StudioProjectVersion>;
  subscription?: Maybe<UserSubscription>;
  subscriptionPlans: Array<SubscriptionPlan>;
  subscriptionUpdatePreview?: Maybe<SubscriptionUpdatePreview>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']['output']>;
  teamProjects: ProjectsConnection;
};


export type UserActorsArgs = {
  inputs?: InputMaybe<FilterAndSearchAvatarsInputs>;
};


export type UserContentModerationFlaggedContentArgs = {
  inputs: ContentModerationFlaggedContentInputs;
};


export type UserFoldersArgs = {
  inputs: FolderInputs;
};


export type UserPersonalProjectsArgs = {
  inputs: ProjectInputs;
};


export type UserProjectArgs = {
  inputs: UserProjectInputs;
};


export type UserSharedProjectsArgs = {
  inputs: ProjectInputs;
};


export type UserSubscriptionUpdatePreviewArgs = {
  inputs: SubscriptionUpdatePreviewInputs;
};


export type UserTeamProjectsArgs = {
  inputs: ProjectInputs;
};

export type FilterAndSearchAvatarsInputs = {
  accessLevel?: InputMaybe<ActorAccessLevel>;
  filters?: InputMaybe<Array<FilterAvatarsInput>>;
  orderBy?: InputMaybe<AvatarOrderByOrder>;
  projectVersion?: InputMaybe<StudioProjectVersion>;
  randomize?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type FilterAvatarsInput = {
  attribute: Scalars['String']['input'];
  category: AvatarTaggingAttributeCategoryEnum;
};

export enum AvatarOrderByOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type Announcement = {
  __typename?: 'Announcement';
  announcementCta?: Maybe<AnnouncementCta>;
  announcementIntervals?: Maybe<Array<AnnouncementInterval>>;
  dismissable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type AnnouncementCta = {
  __typename?: 'AnnouncementCta';
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AnnouncementInterval = {
  __typename?: 'AnnouncementInterval';
  end: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['String']['output'];
};

export type ApiSubscription = {
  __typename?: 'ApiSubscription';
  apiKey: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  endingAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  pausedAt?: Maybe<Scalars['String']['output']>;
  planName: Scalars['String']['output'];
  revokedAt?: Maybe<Scalars['String']['output']>;
  startingAt: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ClipsMetrics = {
  __typename?: 'ClipsMetrics';
  totalRendered: Scalars['Int']['output'];
  totalRenderedByActorId: Scalars['Int']['output'];
};


export type ClipsMetricsTotalRenderedByActorIdArgs = {
  inputs: ClipsMetricsTotalRenderedByActorIdInputs;
};

export type ClipsMetricsTotalRenderedByActorIdInputs = {
  actorId: Scalars['ID']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserContact = {
  __typename?: 'UserContact';
  companyName?: Maybe<Scalars['String']['output']>;
  hasRequestedTrialApiKey: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type ContentModerationFlaggedContentInputs = {
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentModerationFlaggedContent = {
  __typename?: 'ContentModerationFlaggedContent';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  perspective_IDENTITY_ATTACK: Scalars['Float']['output'];
  perspective_INSULT: Scalars['Float']['output'];
  perspective_PROFANITY: Scalars['Float']['output'];
  perspective_SEVERE_TOXICITY: Scalars['Float']['output'];
  perspective_SEXUALLY_EXPLICIT?: Maybe<Scalars['Float']['output']>;
  perspective_THREAT: Scalars['Float']['output'];
  perspective_TOXICITY: Scalars['Float']['output'];
  score: Scalars['Float']['output'];
  text: Scalars['String']['output'];
  wellsaid_PROFANITY?: Maybe<Scalars['Float']['output']>;
};

export type UserDrive = Drive & {
  __typename?: 'UserDrive';
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  driveAccess: DriveAccessRole;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<DriveSettings>;
  teamId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type Drive = {
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  driveAccess: DriveAccessRole;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<DriveSettings>;
  teamId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type DriveAccessRole = {
  __typename?: 'DriveAccessRole';
  role: DriveAccessRoleEnum;
};

export enum DriveAccessRoleEnum {
  Editor = 'editor',
  FullAccess = 'full_access',
  ReadOnly = 'read_only'
}

export type DriveSettings = {
  __typename?: 'DriveSettings';
  color?: Maybe<Scalars['String']['output']>;
};

export type FolderInputs = {
  driveId?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['String']['input']>;
};

export type Folder = {
  __typename?: 'Folder';
  createdAt: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  driveId?: Maybe<Scalars['String']['output']>;
  folderCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectCount: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ProjectInputs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ProjectFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProjectsOrderBy>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectFilters = {
  driveId?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['String']['input']>;
  studioVersion?: InputMaybe<StudioProjectVersion>;
};

export enum ProjectsOrderBy {
  ClientNameAsc = 'clientName_ASC',
  ClientNameDesc = 'clientName_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SequenceAsc = 'sequence_ASC',
  SequenceDesc = 'sequence_DESC'
}

export type ProjectsConnection = Connection & {
  __typename?: 'ProjectsConnection';
  edges: Array<ProjectEdge>;
  pageInfo: PageInfo;
};

export type Connection = {
  pageInfo?: Maybe<PageInfo>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type ProjectEdge = Edge & {
  __typename?: 'ProjectEdge';
  cursor: Scalars['String']['output'];
  node: Project;
};

export type Edge = {
  cursor?: Maybe<Scalars['String']['output']>;
};

export type Project = BaseProject & {
  __typename?: 'Project';
  clientName?: Maybe<Scalars['String']['output']>;
  clips_V2: ClipsConnection;
  clipsCount: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  driveId?: Maybe<Scalars['String']['output']>;
  folderId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectAccess: ProjectAccess;
  replacements?: Maybe<Array<Maybe<PhoneticReplacement>>>;
  sequence: Scalars['BigInt']['output'];
  shareable?: Maybe<ProjectShareable>;
  shareableUrl?: Maybe<Scalars['String']['output']>;
  studioVersion: StudioProjectVersion;
  updatedAt: Scalars['String']['output'];
};


export type ProjectClips_V2Args = {
  inputs?: InputMaybe<ProjectClipsInputs_V2>;
};

export type BaseProject = {
  clientName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ProjectClipsInputs_V2 = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProjectClipsOrderBy>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum ProjectClipsOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreditedAtDescNullsFirstCreatedAtDesc = 'creditedAt_DESC_NullsFirst_createdAt_DESC',
  CreditedAtDescNullsLastCreatedAtDesc = 'creditedAt_DESC_NullsLast_createdAt_DESC',
  IndexAsc = 'index_ASC',
  IndexDesc = 'index_DESC',
  SequenceAsc = 'sequence_ASC',
  SequenceDesc = 'sequence_DESC'
}

export type ClipsConnection = Connection & {
  __typename?: 'ClipsConnection';
  edges: Array<ClipsEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClipsEdge = Edge & {
  __typename?: 'ClipsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  highlights?: Maybe<Array<ClipsSearchResultHighlights>>;
  node: Clip;
};

export type ClipsSearchResultHighlights = SearchResultHighlights & {
  __typename?: 'ClipsSearchResultHighlights';
  fieldName: Scalars['String']['output'];
  highlight: Scalars['String']['output'];
};

export type SearchResultHighlights = {
  fieldName: Scalars['String']['output'];
  highlight: Scalars['String']['output'];
};

export type Clip = BaseClip & {
  __typename?: 'Clip';
  actor: ClipActor;
  actorId: Scalars['ID']['output'];
  actorVariantId: Scalars['ID']['output'];
  audioFormat: Scalars['String']['output'];
  combinedFrom?: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['String']['output'];
  failedWithError?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  isComplete: Scalars['Boolean']['output'];
  isCreditableBy?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  localSrc?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['ID']['output'];
  sequence: Scalars['String']['output'];
  src: Scalars['String']['output'];
  streamRequest?: Maybe<Scalars['StreamRequest']['output']>;
  text: Scalars['String']['output'];
  textFormat?: Maybe<Scalars['String']['output']>;
  ttsVersion?: Maybe<Scalars['String']['output']>;
  wasReCredited?: Maybe<Scalars['Boolean']['output']>;
};

export type BaseClip = {
  actorId: Scalars['ID']['output'];
  actorVariantId: Scalars['ID']['output'];
  audioFormat: Scalars['String']['output'];
  combinedFrom?: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  isComplete: Scalars['Boolean']['output'];
  isCreditableBy?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  sequence: Scalars['String']['output'];
  src: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textFormat?: Maybe<Scalars['String']['output']>;
  ttsVersion?: Maybe<Scalars['String']['output']>;
};

export type ClipActor = {
  __typename?: 'ClipActor';
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  initials: Scalars['String']['output'];
  isCustomActor?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  ttsVersion?: Maybe<Scalars['String']['output']>;
  variant: ActorVariant;
};

export type ProjectAccess = {
  __typename?: 'ProjectAccess';
  id: Scalars['ID']['output'];
  teamMemberAccess?: Maybe<Array<TeamMemberAccess>>;
  teamWideAccessRole?: Maybe<ProjectAccessRole>;
  viewerAccessRole?: Maybe<ProjectAccessRole>;
};

export type TeamMemberAccess = {
  __typename?: 'TeamMemberAccess';
  id: Scalars['ID']['output'];
  projectAccessRole: ProjectAccessRole;
  projectId: Scalars['ID']['output'];
  teamId: Scalars['ID']['output'];
  teamMember: TeamMember;
};

export type ProjectAccessRole = {
  __typename?: 'ProjectAccessRole';
  id: ProjectAccessRoleEnum;
  label: Scalars['String']['output'];
  permissions: Scalars['String']['output'];
};

export enum ProjectAccessRoleEnum {
  Editor = 'editor',
  FullAccess = 'full_access',
  PublicReadOnly = 'public_read_only',
  ReadOnly = 'read_only'
}

export type PhoneticReplacement = {
  __typename?: 'PhoneticReplacement';
  actorId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isRespelling: Scalars['Boolean']['output'];
  libraryId?: Maybe<Scalars['String']['output']>;
  replacement: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type ProjectShareable = {
  __typename?: 'ProjectShareable';
  deletedAt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PhoneticLibrary = {
  __typename?: 'PhoneticLibrary';
  replacement?: Maybe<PhoneticReplacement>;
  replacements?: Maybe<Array<Maybe<PhoneticReplacement>>>;
  replacements_V2?: Maybe<UserReplacements>;
};


export type PhoneticLibraryReplacementArgs = {
  id: Scalars['ID']['input'];
};

export type UserReplacements = {
  __typename?: 'UserReplacements';
  personal?: Maybe<Array<Maybe<PhoneticReplacement>>>;
  team?: Maybe<Array<Maybe<PhoneticReplacement>>>;
};

export type Profile = {
  __typename?: 'Profile';
  company?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  lastIp?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  loginsCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  teamSize?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  verificationEmailSent: Scalars['Boolean']['output'];
};

export type UserProjectInputs = {
  projectId: Scalars['ID']['input'];
};

export type UserSelectionType = {
  __typename?: 'UserSelectionType';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  defaultTTSVersion?: Maybe<TtsVersionSelectOption>;
  displayTTSVersionSelector?: Maybe<Scalars['Boolean']['output']>;
  doNotShowClipDeletionConfirmationModal?: Maybe<Scalars['Boolean']['output']>;
  doNotShowClipPreviewDeletionConfirmationModal?: Maybe<Scalars['Boolean']['output']>;
  doNotShowNavigationConfirmationModal?: Maybe<Scalars['Boolean']['output']>;
  globalAudioFileFormat?: Maybe<AudioFileFormat>;
  globalAudioFileFormatSelected?: Maybe<Scalars['Boolean']['output']>;
  includeClipScriptFormats?: Maybe<Array<ScriptFormat>>;
};

export enum TtsVersionSelectOption {
  Latest = 'latest',
  Next = 'next',
  Previous = 'previous'
}

export enum AudioFileFormat {
  Mp3 = 'mp3',
  Ogg = 'ogg',
  Wav = 'wav'
}

export enum ScriptFormat {
  Txt = 'txt'
}

export type StudioProjectType = {
  __typename?: 'StudioProjectType';
  label: Scalars['String']['output'];
  version: StudioProjectVersion;
};

export type SubscriptionUpdatePreviewInputs = {
  planId: Scalars['String']['input'];
  teamArgs?: InputMaybe<SubscriptionUpdatePreviewTeamInputs>;
};

export type SubscriptionUpdatePreviewTeamInputs = {
  seatCount: Scalars['Int']['input'];
};

export type SubscriptionUpdatePreview = {
  __typename?: 'SubscriptionUpdatePreview';
  amountDue: Scalars['Int']['output'];
  discount?: Maybe<Array<Discount>>;
  proration: SubscriptionUpdatePreviewAmount;
  /** @deprecated Use proration */
  prorationAmount: Scalars['Int']['output'];
  prorationDate: Scalars['Int']['output'];
  /** @deprecated Use remainingInPeriodExcludingTax */
  remainingAmountInPeriodExcludingTax: Scalars['Int']['output'];
  remainingInPeriodExcludingTax: SubscriptionUpdatePreviewAmount;
  tax?: Maybe<Scalars['Int']['output']>;
  totalExcludingTax?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionUpdatePreviewAmount = {
  __typename?: 'SubscriptionUpdatePreviewAmount';
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
};

export type GetFoldersInputs = {
  folderId: Scalars['String']['input'];
};

export type AncestorFolder = {
  __typename?: 'AncestorFolder';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sequence: Scalars['Int']['output'];
};

export type CalculateTaxInputs = {
  addressInfo: AddressInfoInputs;
  amount: Scalars['Int']['input'];
};

export type AddressInfoInputs = {
  shipping?: InputMaybe<ShippingInput>;
  taxId?: InputMaybe<TaxIdInput>;
};

export type ShippingInput = {
  address: AddressInput;
  name: Scalars['String']['input'];
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type TaxIdInput = {
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TaxCalculation = {
  __typename?: 'TaxCalculation';
  amountDue: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  totalExcludingTax: Scalars['Int']['output'];
};

export type ContentTile = {
  __typename?: 'ContentTile';
  audioFile?: Maybe<Scalars['String']['output']>;
  badge?: Maybe<Scalars['String']['output']>;
  body: Scalars['String']['output'];
  cta: ContentTileCta;
  heading: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  location: ContentTileLocation;
  type: ContentTileType;
};

export type ContentTileCta = {
  __typename?: 'ContentTileCTA';
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum ContentTileLocation {
  LeftRail = 'leftRail',
  Resources = 'resources'
}

export enum ContentTileType {
  Share = 'share',
  Tip = 'tip',
  Watch = 'watch'
}

export type Entitlement = {
  __typename?: 'Entitlement';
  dataSource?: Maybe<Scalars['String']['output']>;
  dataSourceId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  features: Array<Feature>;
  id: Scalars['ID']['output'];
  interval: Interval;
  isManaged: Scalars['Boolean']['output'];
  isMultiSeat: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: EntitlementType;
};

export type Feature = {
  __typename?: 'Feature';
  dataType: FeatureDataType;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  interval: Interval;
  isQuota: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum FeatureDataType {
  Auth0Role = 'auth0_role',
  Boolean = 'boolean',
  Number = 'number',
  String = 'string'
}

export enum Interval {
  Day = 'day',
  Month = 'month',
  Perpetual = 'perpetual',
  Week = 'week',
  Year = 'year'
}

export enum EntitlementType {
  StudioAddOn = 'studio_add_on',
  StudioTier = 'studio_tier'
}

export type RespellingSuggestionsInputs = {
  word: Scalars['String']['input'];
};

export type RespellingSuggestion = {
  __typename?: 'respellingSuggestion';
  respelling: Scalars['String']['output'];
  word: Scalars['String']['output'];
};

export type SharedProjectInputs = {
  shortUrlSegment: Scalars['String']['input'];
};

export type Tutorial = {
  __typename?: 'Tutorial';
  category: Scalars['String']['output'];
  featuredImageSrc: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  src: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Tutorial_V2 = {
  __typename?: 'Tutorial_V2';
  body: Scalars['String']['output'];
  cta: TutorialCta;
  footer?: Maybe<Scalars['String']['output']>;
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: TutorialType;
};

export type TutorialCta = {
  __typename?: 'TutorialCTA';
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum TutorialType {
  Cues = 'cues',
  None = 'none',
  Pronunciation = 'pronunciation',
  Voices = 'voices',
  Workflow = 'workflow'
}

export type UserInvitationInputs = {
  email?: InputMaybe<Scalars['String']['input']>;
  invitationCode?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTeamMemberInvitation: AcceptTeamMemberInvitationMutationResponse;
  acceptUserTerms: AcceptUserTermsMutationResponse;
  addTeamMember: AddTeamMemberMutationResponse;
  addTeamSeatsRequest: AddTeamSeatsRequestMutationResponse;
  adminUpgradeUserSubscription?: Maybe<UserSubscriptionUpgradeMutationResponse>;
  bulkCreateEntitlement: BulkCreateEntitlementMutationResponse;
  bulkCreateEntitlementFeature: BulkCreateEntitlementFeatureMutationResponse;
  bulkCreateFeature: BulkCreateFeatureMutationResponse;
  bulkCreateTeamDriveAccess: BulkCreateTeamDriveAccessMutationResponse;
  bulkCreateTeamDrives: BulkCreateTeamDrivesMutationResponse;
  bulkLinkTeamFolders: BulkSoftDeleteTeamDrivesMutationResponse;
  bulkLinkTeamProjects: BulkSoftDeleteTeamDrivesMutationResponse;
  bulkSoftDeleteTeamDrives: BulkSoftDeleteTeamDrivesMutationResponse;
  combineClips: CombineClipsMutationResponse;
  copyClipsToProject: CopyClipsToProjectMutationResponse;
  createClip: CreateClipMutationResponse;
  createDuplicateTeamDrives: CreateDuplicateTeamDriveMutationResponse;
  createFolder: CreateFolderMutationResponse;
  createInvalidDriveProjects: CreateInvalidDriveProjectsMutationResponse;
  createProject: CreateProjectMutationResponse;
  createTeam: AdminCreateTeamMutationResponse;
  deleteClip: DeleteClipMutationResponse;
  deleteClips: DeleteClipsMutationResponse;
  deleteFolder: DeleteFolderMutationResponse;
  deleteProject: DeleteProjectMutationResponse;
  deleteTeamMemberInvitation: DeleteInvitationMutationResponse;
  disableProjectSharing: ProjectSharingDisableMutationResponse;
  downloadClips: DownloadClipsMutationResponse;
  downloadProjectClips: DownloadClipsMutationResponse;
  enableProjectSharing: ProjectSharingEnableMutationResponse;
  getChangePasswordLink: GetChangePasswordLinkMutationResponse;
  importPhoneticLibrary: ImportPhoneticLibraryMutationResponse;
  importPhoneticLibrary_V2: ImportPhoneticLibraryMutationResponseV2;
  inviteTeamMember: CreateInvitationMutationResponse;
  migrateSubscription?: Maybe<MigrateSubscriptionMutationResponse>;
  moveClipsToProject: MoveClipsToProjectMutationResponse;
  moveFiles: MoveFilesMutationResponse;
  moveFolder: MoveFolderMutationResponse;
  moveProject: MoveProjectMutationResponse;
  phoneticReplacement: PhoneticReplacement;
  phoneticReplacementCreate: PhoneticReplacementMutationResponse;
  phoneticReplacementDelete: PhoneticReplacement;
  phoneticReplacementDelete_V2: DeletePhoneticReplacementMutationResponse;
  phoneticReplacementUpdate: PhoneticReplacementMutationResponse;
  /** @deprecated Causes duplicate and spam account data generation */
  preSignupEmailValidation: PreSignupEmailValidationMutationResponse;
  removeTeamMember: RemoveTeamMemberMutationResponse;
  renameFolder: RenameFolderMutationResponse;
  renameProject: UpdateProjectMutationResponse;
  reorderClip: ReorderClipMutationResponse;
  requestApiKey?: Maybe<RequestApiKeyMutationResponse>;
  requestCancelClipRendering: RequestCancelClipRenderingMutationResponse;
  resendTeamMemberInvitation: ResendTeamMemberInvitationMutationResponse;
  restoreClip: RestoreClipMutationResponse;
  restoreClips: RestoreClipsMutationResponse;
  sendVerificationEmail: SendVerificationEmailMutationResponse;
  setProjectReplacements: SetProjectReplacementsMutationResponse;
  tokenizeScript: TokenizeScriptMutationResponse;
  updateAddressInfo: UpdateAddressInfoMutationResponse;
  updateClip: UpdateClipMutationResponse;
  updateManagedTeam: AdminUpdateTeamMutationResponse;
  updatePaymentSource: UpdatePaymentSourceMutationResponse;
  updateProjectAccessBulkTeamMemberAccessRole: UpdateProjectAccessBulkTeamMemberAccessResponse;
  updateProjectAccessTeamMemberAccessRole: UpdateProjectAccessTeamMemberAccessResponse;
  updateProjectAccessTeamWideAccessRole: UpdateProjectAccessTeamWideAccessResponse;
  updateTeam: AdminUpdateTeamMutationResponse;
  updateTeamAvatarSettings?: Maybe<UpdateTeamAvatarSettingsMutationResponse>;
  updateTeamMember: UpdateTeamMemberMutationResponse;
  updateTeamMemberInvitation: UpdateInvitationMutationResponse;
  updateUserAvatarSettings?: Maybe<UpdateUserAvatarSettingsMutationResponse>;
  updateUserContentModeration: UpdateUserContentModerationResponse;
  updateUserProfile: UpdateUserProfileMutationResponse;
  updateUserSelections: UpdateUserSelectionsResponse;
  updateUserSettings: UpdateUserSettingsResponse;
  userSubscriptionCancel: UserSubscriptionCancelMutationRespnse;
  userSubscriptionPlanChange: UserSubscriptionPlanChangeMutationResponse;
  userSubscriptionUndoCancellation: UserSubscriptionUndoCancellationMutationResponse;
  userSubscriptionUpgrade: UserSubscriptionUpgradeMutationResponse;
  validateSubscription: ValidateSubscriptionMutationResponse;
};


export type MutationAcceptTeamMemberInvitationArgs = {
  inputs: AcceptTeamMemberInvitationInputs;
};


export type MutationAddTeamMemberArgs = {
  inputs: AddTeamMemberInputs;
};


export type MutationAddTeamSeatsRequestArgs = {
  inputs: AddTeamSeatsRequestInputs;
};


export type MutationAdminUpgradeUserSubscriptionArgs = {
  inputs: AdminUpgradeUserSubscriptionInputs;
};


export type MutationBulkCreateEntitlementArgs = {
  inputs: BulkCreateEntitlementInputs;
};


export type MutationBulkCreateEntitlementFeatureArgs = {
  inputs: BulkCreateEntitlementFeatureInputs;
};


export type MutationBulkCreateFeatureArgs = {
  inputs: BulkCreateFeatureInputs;
};


export type MutationCombineClipsArgs = {
  inputs: CombineClipsInputs;
};


export type MutationCopyClipsToProjectArgs = {
  inputs?: InputMaybe<CopyClipsToProjectInputs>;
};


export type MutationCreateClipArgs = {
  inputs: CreateClipInputs;
};


export type MutationCreateDuplicateTeamDrivesArgs = {
  inputs: CreateDuplicateTeamDriveInputs;
};


export type MutationCreateFolderArgs = {
  inputs: CreateFolderInputs;
};


export type MutationCreateInvalidDriveProjectsArgs = {
  inputs: CreateInvalidDriveProjectsInputs;
};


export type MutationCreateProjectArgs = {
  inputs: CreateProjectInputs;
};


export type MutationCreateTeamArgs = {
  inputs: AdminCreateTeamInputs;
};


export type MutationDeleteClipArgs = {
  inputs: DeleteClipInputs;
};


export type MutationDeleteClipsArgs = {
  inputs: DeleteClipsInputs;
};


export type MutationDeleteFolderArgs = {
  inputs: DeleteFolderInputs;
};


export type MutationDeleteProjectArgs = {
  inputs: DeleteProjectInputs;
};


export type MutationDeleteTeamMemberInvitationArgs = {
  inputs: DeleteTeamUserInvitationInputs;
};


export type MutationDisableProjectSharingArgs = {
  inputs: ProjectSharingDisableInputs;
};


export type MutationDownloadClipsArgs = {
  inputs: DownloadClipsInputs;
};


export type MutationDownloadProjectClipsArgs = {
  inputs: DownloadProjectClipsInputs;
};


export type MutationEnableProjectSharingArgs = {
  inputs: ProjectSharingEnableInputs;
};


export type MutationImportPhoneticLibraryArgs = {
  file: Scalars['Upload']['input'];
  inputs: ImportPhoneticLibraryInputs;
};


export type MutationImportPhoneticLibrary_V2Args = {
  file: Scalars['Upload']['input'];
  inputs: ImportPhoneticLibraryInputsV2;
};


export type MutationInviteTeamMemberArgs = {
  inputs: CreateTeamUserInvitationInputs;
};


export type MutationMigrateSubscriptionArgs = {
  inputs: MigrateSubscriptionInputs;
};


export type MutationMoveClipsToProjectArgs = {
  inputs: MoveClipsToProjectInputs;
};


export type MutationMoveFilesArgs = {
  inputs: MoveFilesInputs;
};


export type MutationMoveFolderArgs = {
  inputs: MoveFolderInputs;
};


export type MutationMoveProjectArgs = {
  inputs: MoveProjectInputs;
};


export type MutationPhoneticReplacementArgs = {
  inputs: PhoneticReplacementInputs;
};


export type MutationPhoneticReplacementCreateArgs = {
  inputs: PhoneticReplacementInputs;
};


export type MutationPhoneticReplacementDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPhoneticReplacementDelete_V2Args = {
  id: Scalars['ID']['input'];
};


export type MutationPhoneticReplacementUpdateArgs = {
  inputs: PhoneticReplacementInputs;
};


export type MutationPreSignupEmailValidationArgs = {
  inputs: PreSignupEmailValidationInputs;
};


export type MutationRemoveTeamMemberArgs = {
  inputs: RemoveTeamMemberInputs;
};


export type MutationRenameFolderArgs = {
  inputs: RenameFolderInputs;
};


export type MutationRenameProjectArgs = {
  inputs: RenameProjectInputs;
};


export type MutationReorderClipArgs = {
  inputs: ReorderClipInputs;
};


export type MutationRequestApiKeyArgs = {
  inputs: RequestApiKeyInputs;
};


export type MutationRequestCancelClipRenderingArgs = {
  inputs: RequestCancelClipRenderingInputs;
};


export type MutationResendTeamMemberInvitationArgs = {
  inputs: ResendTeamMemberInvitationInputs;
};


export type MutationRestoreClipArgs = {
  inputs: RestoreClipInputs;
};


export type MutationRestoreClipsArgs = {
  inputs: RestoreClipsInputs;
};


export type MutationSendVerificationEmailArgs = {
  inputs: SendVerificationEmailInputs;
};


export type MutationSetProjectReplacementsArgs = {
  inputs: SetProjectReplacementsInputs;
};


export type MutationTokenizeScriptArgs = {
  inputs: TokenizeScriptInputs;
};


export type MutationUpdateAddressInfoArgs = {
  inputs: AddressInfoInputs;
};


export type MutationUpdateClipArgs = {
  inputs: UpdateClipInputs;
};


export type MutationUpdateManagedTeamArgs = {
  inputs: AdminUpdateTeamInputs;
};


export type MutationUpdatePaymentSourceArgs = {
  inputs: UpdatePaymentSourceInputs;
};


export type MutationUpdateProjectAccessBulkTeamMemberAccessRoleArgs = {
  inputs: UpdateBulkTeamMemberAccessInputs;
};


export type MutationUpdateProjectAccessTeamMemberAccessRoleArgs = {
  inputs: UpdateTeamMemberAccessInputs;
};


export type MutationUpdateProjectAccessTeamWideAccessRoleArgs = {
  inputs: UpdateTeamWideAccessInputs;
};


export type MutationUpdateTeamArgs = {
  inputs: AdminUpdateTeamInputs;
};


export type MutationUpdateTeamAvatarSettingsArgs = {
  inputs: UpdateTeamAvatarSettingsInputs;
};


export type MutationUpdateTeamMemberArgs = {
  inputs: UpdateTeamMemberInputs;
};


export type MutationUpdateTeamMemberInvitationArgs = {
  inputs: UpdateTeamUserInvitationInputs;
};


export type MutationUpdateUserAvatarSettingsArgs = {
  inputs: UpdateUserAvatarSettingsInputs;
};


export type MutationUpdateUserContentModerationArgs = {
  inputs: UpdateUserContentModerationInputs;
};


export type MutationUpdateUserProfileArgs = {
  inputs: UpdateUserProfileInputs;
};


export type MutationUpdateUserSelectionsArgs = {
  inputs: UpdateUserSelectionsInputs;
};


export type MutationUpdateUserSettingsArgs = {
  inputs: UpdateUserSettingsInputs;
};


export type MutationUserSubscriptionPlanChangeArgs = {
  inputs: UserSubscriptionPlanChangeInputs;
};


export type MutationUserSubscriptionUpgradeArgs = {
  inputs: UserSubscriptionUpgradeInputs;
};


export type MutationValidateSubscriptionArgs = {
  inputs: ValidateSubscriptionInputs;
};

export type AcceptTeamMemberInvitationInputs = {
  invitationCode: Scalars['String']['input'];
};

export type AcceptTeamMemberInvitationMutationResponse = MutationResponse & {
  __typename?: 'AcceptTeamMemberInvitationMutationResponse';
  code: Scalars['String']['output'];
  invitation?: Maybe<UserInvitation>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MutationResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AcceptUserTermsMutationResponse = MutationResponse & {
  __typename?: 'AcceptUserTermsMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AddTeamMemberInputs = {
  email?: InputMaybe<Scalars['String']['input']>;
  role: TeamRole;
  teamId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddTeamMemberMutationResponse = MutationResponse & {
  __typename?: 'AddTeamMemberMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  teamMember?: Maybe<TeamMember>;
};

export type AddTeamSeatsRequestInputs = {
  formContext?: InputMaybe<HubSpotFormContext>;
  seatCount: Scalars['Int']['input'];
  teamId: Scalars['ID']['input'];
};

export type HubSpotFormContext = {
  pageName?: InputMaybe<Scalars['String']['input']>;
  pageUri?: InputMaybe<Scalars['String']['input']>;
};

export type AddTeamSeatsRequestMutationResponse = MutationResponse & {
  __typename?: 'AddTeamSeatsRequestMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AdminUpgradeUserSubscriptionInputs = {
  clipGenerationLimit?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  ownerEmail: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  projectLimit?: InputMaybe<Scalars['Int']['input']>;
  seatCount: Scalars['Int']['input'];
  stripeResourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserSubscriptionUpgradeMutationResponse = MutationResponse & {
  __typename?: 'UserSubscriptionUpgradeMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription?: Maybe<UserSubscription>;
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type BulkCreateEntitlementInputs = {
  entitlements: Array<CreateEntitlementInputs>;
};

export type CreateEntitlementInputs = {
  dataSource: Scalars['String']['input'];
  dataSourceId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  featureIds?: InputMaybe<Array<Scalars['String']['input']>>;
  interval: Interval;
  isManaged?: InputMaybe<Scalars['Boolean']['input']>;
  isMultiSeat?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  type: EntitlementType;
};

export type BulkCreateEntitlementMutationResponse = MutationResponse & {
  __typename?: 'BulkCreateEntitlementMutationResponse';
  code: Scalars['String']['output'];
  entitlements?: Maybe<Array<Entitlement>>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BulkCreateEntitlementFeatureInputs = {
  entitlementFeatures: Array<EntitlementFeatureInput>;
};

export type EntitlementFeatureInput = {
  entitlementId: Scalars['String']['input'];
  featureIds: Array<Scalars['String']['input']>;
};

export type BulkCreateEntitlementFeatureMutationResponse = MutationResponse & {
  __typename?: 'BulkCreateEntitlementFeatureMutationResponse';
  code: Scalars['String']['output'];
  entitlementFeatures?: Maybe<Array<EntitlementFeature>>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type EntitlementFeature = {
  __typename?: 'EntitlementFeature';
  entitlementId: Scalars['String']['output'];
  featureIds: Array<Scalars['String']['output']>;
};

export type BulkCreateFeatureInputs = {
  features: Array<CreateFeatureInputs>;
};

export type CreateFeatureInputs = {
  dataType: FeatureDataType;
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  interval: Interval;
  isQuota?: InputMaybe<Scalars['Boolean']['input']>;
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type BulkCreateFeatureMutationResponse = MutationResponse & {
  __typename?: 'BulkCreateFeatureMutationResponse';
  code: Scalars['String']['output'];
  features?: Maybe<Array<Feature>>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BulkCreateTeamDriveAccessMutationResponse = MutationResponse & {
  __typename?: 'BulkCreateTeamDriveAccessMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BulkCreateTeamDrivesMutationResponse = MutationResponse & {
  __typename?: 'BulkCreateTeamDrivesMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BulkSoftDeleteTeamDrivesMutationResponse = MutationResponse & {
  __typename?: 'BulkSoftDeleteTeamDrivesMutationResponse';
  code: Scalars['String']['output'];
  data?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CombineClipsInputs = {
  clipIds: Array<Scalars['ID']['input']>;
  combinedClipDisplayName: Scalars['String']['input'];
  msGapDuration: Scalars['Int']['input'];
};

export type CombineClipsMutationResponse = MutationResponse & {
  __typename?: 'CombineClipsMutationResponse';
  baseClip?: Maybe<BaseClipType>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BaseClipType = BaseClip & {
  __typename?: 'BaseClipType';
  actorId: Scalars['ID']['output'];
  actorVariantId: Scalars['ID']['output'];
  audioFormat: Scalars['String']['output'];
  combinedFrom?: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  isComplete: Scalars['Boolean']['output'];
  isCreditableBy?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  sequence: Scalars['String']['output'];
  src: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textFormat?: Maybe<Scalars['String']['output']>;
  ttsVersion?: Maybe<Scalars['String']['output']>;
};

export type CopyClipsToProjectInputs = {
  clipIds: Array<Scalars['String']['input']>;
  destinationProjectId: Scalars['String']['input'];
};

export type CopyClipsToProjectMutationResponse = MutationResponse & {
  __typename?: 'CopyClipsToProjectMutationResponse';
  clips?: Maybe<Array<Clip>>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type CreateClipInputs = {
  clipId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  speakerId: Scalars['String']['input'];
  speakerVariantId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  textFormat?: InputMaybe<Scalars['String']['input']>;
  ttsVersion: Scalars['String']['input'];
};

export type CreateClipMutationResponse = MutationResponse & {
  __typename?: 'CreateClipMutationResponse';
  clip?: Maybe<Clip>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateDuplicateTeamDriveInputs = {
  name: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CreateDuplicateTeamDriveMutationResponse = MutationResponse & {
  __typename?: 'CreateDuplicateTeamDriveMutationResponse';
  code: Scalars['String']['output'];
  data?: Maybe<Array<Maybe<CreateDuplicateTeamDriveData>>>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateDuplicateTeamDriveData = {
  __typename?: 'CreateDuplicateTeamDriveData';
  creatorId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  teamId?: Maybe<Scalars['String']['output']>;
};

export type CreateFolderInputs = {
  ancestorId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  driveId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateFolderMutationResponse = MutationResponse & {
  __typename?: 'CreateFolderMutationResponse';
  code: Scalars['String']['output'];
  folder?: Maybe<Folder>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateInvalidDriveProjectsInputs = {
  isMigrated?: InputMaybe<Scalars['Boolean']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
};

export type CreateInvalidDriveProjectsMutationResponse = MutationResponse & {
  __typename?: 'CreateInvalidDriveProjectsMutationResponse';
  code: Scalars['String']['output'];
  data?: Maybe<Array<Maybe<CreateInvalidDriveProjectData>>>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateInvalidDriveProjectData = {
  __typename?: 'CreateInvalidDriveProjectData';
  creatorId?: Maybe<Scalars['String']['output']>;
  driveId?: Maybe<Scalars['String']['output']>;
  folderId?: Maybe<Scalars['String']['output']>;
  projectClientName?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  studioVersion?: Maybe<StudioProjectVersion>;
};

export type CreateProjectInputs = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  driveId?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  studioVersion?: InputMaybe<StudioProjectVersion>;
};

export type CreateProjectMutationResponse = MutationResponse & {
  __typename?: 'CreateProjectMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project?: Maybe<Project>;
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type AdminCreateTeamInputs = {
  clipGenerationLimit?: InputMaybe<Scalars['Int']['input']>;
  companyName: Scalars['String']['input'];
  dealId?: InputMaybe<Scalars['String']['input']>;
  ownerEmail: Scalars['String']['input'];
  ownerName: Scalars['String']['input'];
  projectLimit?: InputMaybe<Scalars['Int']['input']>;
  seatCount: Scalars['Int']['input'];
  ssoConnectionName?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
};

export type AdminCreateTeamMutationResponse = MutationResponse & {
  __typename?: 'AdminCreateTeamMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  team?: Maybe<Team>;
};

export type DeleteClipInputs = {
  clipId: Scalars['ID']['input'];
};

export type DeleteClipMutationResponse = MutationResponse & {
  __typename?: 'DeleteClipMutationResponse';
  clip?: Maybe<Clip>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  wasReCredited?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteClipsInputs = {
  clipIds: Array<Scalars['ID']['input']>;
};

export type DeleteClipsMutationResponse = MutationResponse & {
  __typename?: 'DeleteClipsMutationResponse';
  clips?: Maybe<Array<Maybe<Clip>>>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  numClipsDeleted?: Maybe<Scalars['Int']['output']>;
  numClipsRecredited?: Maybe<Scalars['Int']['output']>;
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type DeleteFolderInputs = {
  folderId: Scalars['String']['input'];
};

export type DeleteFolderMutationResponse = MutationResponse & {
  __typename?: 'DeleteFolderMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteProjectInputs = {
  projectId: Scalars['ID']['input'];
};

export type DeleteProjectMutationResponse = MutationResponse & {
  __typename?: 'DeleteProjectMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  projectId?: Maybe<Scalars['ID']['output']>;
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type DeleteTeamUserInvitationInputs = {
  id: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type DeleteInvitationMutationResponse = MutationResponse & {
  __typename?: 'DeleteInvitationMutationResponse';
  code: Scalars['String']['output'];
  invitation?: Maybe<UserInvitation>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ProjectSharingDisableInputs = {
  projectId: Scalars['String']['input'];
};

export type ProjectSharingDisableMutationResponse = MutationResponse & {
  __typename?: 'ProjectSharingDisableMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project?: Maybe<Project>;
  success: Scalars['Boolean']['output'];
};

export type DownloadClipsInputs = {
  audioFileFormat: AudioFileFormat;
  clientTimeZone?: InputMaybe<Scalars['String']['input']>;
  clipIds: Array<Scalars['String']['input']>;
  includeScriptFormats?: InputMaybe<Array<ScriptFormat>>;
};

export type DownloadClipsMutationResponse = MutationResponse & {
  __typename?: 'DownloadClipsMutationResponse';
  code: Scalars['String']['output'];
  data?: Maybe<DownloadData>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DownloadData = {
  __typename?: 'DownloadData';
  downloadMetricsMetadata: DownloadMetricsMetadata;
  fileFormat: DownloadFileFormat;
  friendlyFileName: Scalars['String']['output'];
  gcsFileName: Scalars['String']['output'];
  signedURL: Scalars['String']['output'];
};

export type DownloadMetricsMetadata = {
  __typename?: 'DownloadMetricsMetadata';
  combinedTextLength: Scalars['Int']['output'];
  numberOfClips: Scalars['Int']['output'];
  numberOfClipsJustConverted: Scalars['Int']['output'];
  zipFileAlreadyExisted?: Maybe<Scalars['Boolean']['output']>;
};

export enum DownloadFileFormat {
  Mp3 = 'mp3',
  Ogg = 'ogg',
  Wav = 'wav',
  Zip = 'zip'
}

export type DownloadProjectClipsInputs = {
  audioFileFormat: AudioFileFormat;
  clientTimeZone?: InputMaybe<Scalars['String']['input']>;
  includeScriptFormats?: InputMaybe<Array<ScriptFormat>>;
  projectId: Scalars['ID']['input'];
};

export type ProjectSharingEnableInputs = {
  projectId: Scalars['String']['input'];
};

export type ProjectSharingEnableMutationResponse = MutationResponse & {
  __typename?: 'ProjectSharingEnableMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project?: Maybe<Project>;
  success: Scalars['Boolean']['output'];
};

export type GetChangePasswordLinkMutationResponse = MutationResponse & {
  __typename?: 'GetChangePasswordLinkMutationResponse';
  code: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ImportPhoneticLibraryInputs = {
  importBehavior: ImportPhoneticLibraryOptions;
};

export enum ImportPhoneticLibraryOptions {
  ReplaceAll = 'replaceAll',
  ReplaceDuplicates = 'replaceDuplicates',
  ReplaceNone = 'replaceNone'
}

export type ImportPhoneticLibraryMutationResponse = MutationResponse & {
  __typename?: 'ImportPhoneticLibraryMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ImportPhoneticLibraryInputsV2 = {
  libraryType?: InputMaybe<PhoneticLibraryType>;
};

export enum PhoneticLibraryType {
  Personal = 'personal',
  Team = 'team'
}

export type ImportPhoneticLibraryMutationResponseV2 = MutationResponse & {
  __typename?: 'ImportPhoneticLibraryMutationResponseV2';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  newEntries?: Maybe<Array<Maybe<PhoneticReplacement>>>;
  success: Scalars['Boolean']['output'];
};

export type CreateTeamUserInvitationInputs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  /** When true, it'll send an email to the invitee on success. */
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['ID']['input'];
  teamRole: TeamRole;
};

export type CreateInvitationMutationResponse = MutationResponse & {
  __typename?: 'CreateInvitationMutationResponse';
  code: Scalars['String']['output'];
  invitation?: Maybe<UserInvitation>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MigrateSubscriptionInputs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  disableValidation?: InputMaybe<Scalars['Boolean']['input']>;
  endDate: Scalars['Int']['input'];
  overrides?: InputMaybe<FeatureOverrides>;
  plans: Array<Scalars['String']['input']>;
  startDate: Scalars['Int']['input'];
  stripeSubscriptionId: Scalars['String']['input'];
  subscriptionStatus: Scalars['String']['input'];
  teamsSeatCount?: InputMaybe<Scalars['Int']['input']>;
  trialEndDate?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureOverrides = {
  clipDownloadLimit?: InputMaybe<Scalars['Int']['input']>;
  editorCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
  projectLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type MigrateSubscriptionMutationResponse = MutationResponse & {
  __typename?: 'MigrateSubscriptionMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MoveClipsToProjectInputs = {
  clipIds: Array<Scalars['String']['input']>;
  clipIndexes?: InputMaybe<Array<Scalars['Int']['input']>>;
  clipSequences?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationProjectId: Scalars['String']['input'];
};

export type MoveClipsToProjectMutationResponse = MutationResponse & {
  __typename?: 'MoveClipsToProjectMutationResponse';
  clips?: Maybe<Array<Clip>>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MoveFilesInputs = {
  driveId?: InputMaybe<Scalars['String']['input']>;
  folderIds: Array<Scalars['String']['input']>;
  newAncestorId?: InputMaybe<Scalars['String']['input']>;
  projectIds: Array<Scalars['String']['input']>;
};

export type MoveFilesMutationResponse = MutationResponse & {
  __typename?: 'MoveFilesMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MoveFolderInputs = {
  driveId?: InputMaybe<Scalars['String']['input']>;
  folderIds: Array<Scalars['String']['input']>;
  newAncestorId?: InputMaybe<Scalars['String']['input']>;
};

export type MoveFolderMutationResponse = MutationResponse & {
  __typename?: 'MoveFolderMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MoveProjectInputs = {
  precedingProjectId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type MoveProjectMutationResponse = MutationResponse & {
  __typename?: 'MoveProjectMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PhoneticReplacementInputs = {
  actorId?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isRespelling?: InputMaybe<Scalars['Boolean']['input']>;
  libraryType?: InputMaybe<PhoneticLibraryType>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  replacement: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type PhoneticReplacementMutationResponse = MutationResponse & {
  __typename?: 'PhoneticReplacementMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  replacement?: Maybe<PhoneticReplacement>;
  success: Scalars['Boolean']['output'];
};

export type DeletePhoneticReplacementMutationResponse = MutationResponse & {
  __typename?: 'DeletePhoneticReplacementMutationResponse';
  code: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PreSignupEmailValidationInputs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  emailValidationResult?: InputMaybe<EmailValidationResult>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isTeam?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type EmailValidationResult = {
  acceptAll: Scalars['Boolean']['input'];
  disposable: Scalars['Boolean']['input'];
  free: Scalars['Boolean']['input'];
  reason: Scalars['String']['input'];
  result: Scalars['String']['input'];
  role: Scalars['Boolean']['input'];
  sendexScore: Scalars['Float']['input'];
};

export type PreSignupEmailValidationMutationResponse = MutationResponse & {
  __typename?: 'PreSignupEmailValidationMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RemoveTeamMemberInputs = {
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveTeamMemberMutationResponse = MutationResponse & {
  __typename?: 'RemoveTeamMemberMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  teamId?: Maybe<Scalars['ID']['output']>;
  teamMemberId?: Maybe<Scalars['ID']['output']>;
};

export type RenameFolderInputs = {
  description?: InputMaybe<Scalars['String']['input']>;
  folderId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RenameFolderMutationResponse = MutationResponse & {
  __typename?: 'RenameFolderMutationResponse';
  code: Scalars['String']['output'];
  folder?: Maybe<Folder>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RenameProjectInputs = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
};

export type UpdateProjectMutationResponse = MutationResponse & {
  __typename?: 'UpdateProjectMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project?: Maybe<Project>;
  success: Scalars['Boolean']['output'];
};

export type ReorderClipInputs = {
  clipId: Scalars['ID']['input'];
  precedingClipId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type ReorderClipMutationResponse = MutationResponse & {
  __typename?: 'ReorderClipMutationResponse';
  clip?: Maybe<Clip>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RequestApiKeyInputs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  usageReason: Scalars['String']['input'];
};

export type RequestApiKeyMutationResponse = MutationResponse & {
  __typename?: 'RequestApiKeyMutationResponse';
  apiKey?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RequestCancelClipRenderingInputs = {
  id: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type RequestCancelClipRenderingMutationResponse = MutationResponse & {
  __typename?: 'RequestCancelClipRenderingMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ResendTeamMemberInvitationInputs = {
  invitationId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type ResendTeamMemberInvitationMutationResponse = MutationResponse & {
  __typename?: 'ResendTeamMemberInvitationMutationResponse';
  code: Scalars['String']['output'];
  invitation?: Maybe<UserInvitation>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RestoreClipInputs = {
  clipId: Scalars['ID']['input'];
};

export type RestoreClipMutationResponse = MutationResponse & {
  __typename?: 'RestoreClipMutationResponse';
  clip?: Maybe<Clip>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type RestoreClipsInputs = {
  clipIds: Array<Scalars['ID']['input']>;
};

export type RestoreClipsMutationResponse = MutationResponse & {
  __typename?: 'RestoreClipsMutationResponse';
  clips?: Maybe<Array<Maybe<Clip>>>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  numClipsRestored?: Maybe<Scalars['Int']['output']>;
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type SendVerificationEmailInputs = {
  via?: InputMaybe<Scalars['String']['input']>;
};

export type SendVerificationEmailMutationResponse = MutationResponse & {
  __typename?: 'SendVerificationEmailMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SetProjectReplacementsInputs = {
  projectId: Scalars['String']['input'];
  replacementIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SetProjectReplacementsMutationResponse = MutationResponse & {
  __typename?: 'SetProjectReplacementsMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  project: Project;
  success: Scalars['Boolean']['output'];
};

export type TokenizeScriptInputs = {
  script: Array<Scalars['String']['input']>;
};

export type TokenizeScriptMutationResponse = MutationResponse & {
  __typename?: 'TokenizeScriptMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  tokens: Array<Array<Maybe<Scalars['String']['output']>>>;
};

export type UpdateAddressInfoMutationResponse = MutationResponse & {
  __typename?: 'UpdateAddressInfoMutationResponse';
  addressInfo?: Maybe<AddressInfo>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateClipInputs = {
  clipId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClipMutationResponse = MutationResponse & {
  __typename?: 'UpdateClipMutationResponse';
  clip?: Maybe<Clip>;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AdminUpdateTeamInputs = {
  clipGenerationLimit?: InputMaybe<Scalars['Int']['input']>;
  companyName: Scalars['String']['input'];
  dealId?: InputMaybe<Scalars['String']['input']>;
  projectLimit?: InputMaybe<Scalars['Int']['input']>;
  seatCount: Scalars['Int']['input'];
  ssoConnectionName?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type AdminUpdateTeamMutationResponse = MutationResponse & {
  __typename?: 'AdminUpdateTeamMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  team?: Maybe<Team>;
};

export type UpdatePaymentSourceInputs = {
  source: Scalars['String']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type UpdatePaymentSourceMutationResponse = MutationResponse & {
  __typename?: 'UpdatePaymentSourceMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription?: Maybe<UserSubscription>;
  success: Scalars['Boolean']['output'];
};

export type UpdateBulkTeamMemberAccessInputs = {
  projectId: Scalars['String']['input'];
  /** role is either ProjectAccessRoleEnum or null; null will remove team members access */
  role?: InputMaybe<ProjectAccessRoleEnum>;
  teamId: Scalars['String']['input'];
  /** teamMemberIds include the ids of the users whose project access role will be updated */
  teamMemberIds: Array<Scalars['String']['input']>;
};

export type UpdateProjectAccessBulkTeamMemberAccessResponse = MutationResponse & {
  __typename?: 'UpdateProjectAccessBulkTeamMemberAccessResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  role?: Maybe<ProjectAccessRole>;
  success: Scalars['Boolean']['output'];
  teamMemberIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type UpdateTeamMemberAccessInputs = {
  projectId: Scalars['String']['input'];
  /** role is either ProjectAccessRoleEnum or null; null will remove team member access */
  role?: InputMaybe<ProjectAccessRoleEnum>;
  teamId: Scalars['String']['input'];
  /** teamMemberId is the id of the user whose project access role will be updated */
  teamMemberId: Scalars['String']['input'];
};

export type UpdateProjectAccessTeamMemberAccessResponse = MutationResponse & {
  __typename?: 'UpdateProjectAccessTeamMemberAccessResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  role?: Maybe<ProjectAccessRole>;
  success: Scalars['Boolean']['output'];
  teamMemberId?: Maybe<Scalars['String']['output']>;
};

export type UpdateTeamWideAccessInputs = {
  projectId: Scalars['String']['input'];
  /** role is either ProjectAccessRoleEnum or null; null will remove team wide access */
  role?: InputMaybe<ProjectAccessRoleEnum>;
  teamId: Scalars['String']['input'];
};

export type UpdateProjectAccessTeamWideAccessResponse = MutationResponse & {
  __typename?: 'UpdateProjectAccessTeamWideAccessResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  role?: Maybe<ProjectAccessRole>;
  success: Scalars['Boolean']['output'];
  teamMemberAccess?: Maybe<Array<TeamMemberAccess>>;
};

export type UpdateTeamAvatarSettingsInputs = {
  action: TeamAvatarSettingsAction;
  avatarId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  variantId?: InputMaybe<Scalars['String']['input']>;
};

export enum TeamAvatarSettingsAction {
  Favorite = 'Favorite',
  Unfavorite = 'Unfavorite'
}

export type UpdateTeamAvatarSettingsMutationResponse = MutationResponse & {
  __typename?: 'UpdateTeamAvatarSettingsMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateTeamMemberInputs = {
  memberId: Scalars['ID']['input'];
  role: TeamRole;
  teamId: Scalars['ID']['input'];
};

export type UpdateTeamMemberMutationResponse = MutationResponse & {
  __typename?: 'UpdateTeamMemberMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  teamMember?: Maybe<TeamMember>;
};

export type UpdateTeamUserInvitationInputs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  teamId: Scalars['ID']['input'];
  teamRole: TeamRole;
};

export type UpdateInvitationMutationResponse = MutationResponse & {
  __typename?: 'UpdateInvitationMutationResponse';
  code: Scalars['String']['output'];
  invitation?: Maybe<UserInvitation>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateUserAvatarSettingsInputs = {
  action: UserAvatarSettingsAction;
  avatarId: Scalars['String']['input'];
  variantId?: InputMaybe<Scalars['String']['input']>;
};

export enum UserAvatarSettingsAction {
  Favorite = 'Favorite',
  Hide = 'Hide',
  Unfavorite = 'Unfavorite',
  Unhide = 'Unhide'
}

export type UpdateUserAvatarSettingsMutationResponse = MutationResponse & {
  __typename?: 'UpdateUserAvatarSettingsMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateUserContentModerationInputs = {
  allowlist?: InputMaybe<Scalars['Boolean']['input']>;
  block?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
  whitelist?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateUserContentModerationResponse = MutationResponse & {
  __typename?: 'UpdateUserContentModerationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

export type UpdateUserProfileInputs = {
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserProfileMutationResponse = MutationResponse & {
  __typename?: 'UpdateUserProfileMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  success: Scalars['Boolean']['output'];
};

export type UpdateUserSelectionsInputs = {
  selections: Array<UserSelection>;
};

export type UserSelection = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateUserSelectionsResponse = MutationResponse & {
  __typename?: 'UpdateUserSelectionsResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  selections?: Maybe<Array<Maybe<UserSelectionType>>>;
  success: Scalars['Boolean']['output'];
};

export type UpdateUserSettingsInputs = {
  defaultTTSVersion?: InputMaybe<TtsVersionSelectOption>;
  displayTTSVersionSelector?: InputMaybe<Scalars['Boolean']['input']>;
  doNotShowClipDeletionConfirmationModal?: InputMaybe<Scalars['Boolean']['input']>;
  doNotShowClipPreviewDeletionConfirmationModal?: InputMaybe<Scalars['Boolean']['input']>;
  doNotShowNavigationConfirmationModal?: InputMaybe<Scalars['Boolean']['input']>;
  globalAudioFileFormat?: InputMaybe<AudioFileFormat>;
  globalAudioFileFormatSelected?: InputMaybe<Scalars['Boolean']['input']>;
  includeClipScriptFormats?: InputMaybe<Array<ScriptFormat>>;
};

export type UpdateUserSettingsResponse = MutationResponse & {
  __typename?: 'UpdateUserSettingsResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  settings?: Maybe<UserSettings>;
  success: Scalars['Boolean']['output'];
};

export type UserSubscriptionCancelMutationRespnse = MutationResponse & {
  __typename?: 'UserSubscriptionCancelMutationRespnse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription?: Maybe<UserSubscription>;
  success: Scalars['Boolean']['output'];
};

export type UserSubscriptionPlanChangeInputs = {
  addressInfo?: InputMaybe<AddressInfoInputs>;
  paymentSource?: InputMaybe<Scalars['String']['input']>;
  prorationDate: Scalars['Int']['input'];
  subscriptionPlanId: Scalars['ID']['input'];
  teamArgs?: InputMaybe<SubscriptionPlanChangeTeamInputs>;
};

export type SubscriptionPlanChangeTeamInputs = {
  seatCount: Scalars['Int']['input'];
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export type UserSubscriptionPlanChangeMutationResponse = MutationResponse & {
  __typename?: 'UserSubscriptionPlanChangeMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription?: Maybe<UserSubscription>;
  success: Scalars['Boolean']['output'];
  teamProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
  userProductAccessPeriodUsage?: Maybe<ProductAccessPeriodUsage>;
};

export type UserSubscriptionUndoCancellationMutationResponse = MutationResponse & {
  __typename?: 'UserSubscriptionUndoCancellationMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription?: Maybe<UserSubscription>;
  success: Scalars['Boolean']['output'];
};

export type UserSubscriptionUpgradeInputs = {
  addressInfo?: InputMaybe<AddressInfoInputs>;
  paymentSource?: InputMaybe<Scalars['String']['input']>;
  referralId?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlanId: Scalars['ID']['input'];
  teamArgs?: InputMaybe<SubscriptionUpgradeTeamInputs>;
};

export type SubscriptionUpgradeTeamInputs = {
  seatCount: Scalars['Int']['input'];
  teamName: Scalars['String']['input'];
};

export type ValidateSubscriptionInputs = {
  stripeSubscriptionId: Scalars['String']['input'];
};

export type ValidateSubscriptionMutationResponse = MutationResponse & {
  __typename?: 'ValidateSubscriptionMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  migrationErrors?: Maybe<MigrationErrors>;
  migrationStatus: MigrationStatus;
  success: Scalars['Boolean']['output'];
};

export type MigrationErrors = {
  __typename?: 'MigrationErrors';
  queryDiffs?: Maybe<Array<QueryDiff>>;
};

export type QueryDiff = {
  __typename?: 'QueryDiff';
  fieldDiffs: Array<FieldDiff>;
  queryName: Scalars['String']['output'];
};

export type FieldDiff = {
  __typename?: 'FieldDiff';
  actualValue: Scalars['String']['output'];
  expectedValue: Scalars['String']['output'];
  fieldPath: Scalars['String']['output'];
};

export enum MigrationStatus {
  Complete = 'complete',
  Error = 'error',
  Migrating = 'migrating',
  NeedsReview = 'needs_review'
}

export type Subscription = {
  __typename?: 'Subscription';
  clipEntityChanged: ClipEntityChangedEvent;
  projectAccessChanged: ChangeProjectAccessEvent;
  projectAccessEntityChanged: ProjectAccessEntityChangedEvent;
  projectEntityChanged: ProjectEntityChangedEvent;
  projectTypeChanged: ChangeProjectTypeEvent;
};


export type SubscriptionClipEntityChangedArgs = {
  inputs: ClipEntityChangedSubscriptionInputs;
};


export type SubscriptionProjectAccessEntityChangedArgs = {
  inputs: ProjectEntityChangedSubscriptionInputs;
};


export type SubscriptionProjectEntityChangedArgs = {
  inputs: ProjectEntityChangedSubscriptionInputs;
};

export type ClipEntityChangedSubscriptionInputs = {
  projectId: Scalars['String']['input'];
};

export type ClipEntityChangedEvent = EntityChangedEvent & {
  __typename?: 'ClipEntityChangedEvent';
  changeType: EntityChangeType;
  clipEntity?: Maybe<SubscriptionClip>;
  clipId: Scalars['String']['output'];
  eventTime: Scalars['String']['output'];
  previousProjectId?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['String']['output'];
};

export type EntityChangedEvent = {
  changeType: EntityChangeType;
};

export enum EntityChangeType {
  Created = 'Created',
  Deleted = 'Deleted',
  Updated = 'Updated'
}

export type SubscriptionClip = BaseClip & {
  __typename?: 'SubscriptionClip';
  actor: ClipActor;
  actorId: Scalars['ID']['output'];
  actorVariantId: Scalars['ID']['output'];
  audioFormat: Scalars['String']['output'];
  combinedFrom?: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  isComplete: Scalars['Boolean']['output'];
  isCreditableBy?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  sequence: Scalars['String']['output'];
  src: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textFormat?: Maybe<Scalars['String']['output']>;
  ttsVersion?: Maybe<Scalars['String']['output']>;
};

export type ChangeProjectAccessEvent = {
  __typename?: 'ChangeProjectAccessEvent';
  action: ProjectAccessActionEnum;
  projectId: Scalars['String']['output'];
  subject: ProjectAccessChangedSubject;
};

export enum ProjectAccessActionEnum {
  Add = 'add',
  Remove = 'remove'
}

export enum ProjectAccessChangedSubject {
  Personal = 'personal',
  Shared = 'shared'
}

export type ProjectEntityChangedSubscriptionInputs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ProjectTypeEnum>;
};

export enum ProjectTypeEnum {
  Personal = 'personal',
  Shared = 'shared',
  Team = 'team'
}

export type ProjectAccessEntityChangedEvent = EntityChangedEvent & {
  __typename?: 'ProjectAccessEntityChangedEvent';
  changeType: EntityChangeType;
  eventTime: Scalars['String']['output'];
  projectAccessEntity: SubscriptionProjectAccess;
  projectId: Scalars['String']['output'];
};

export type SubscriptionProjectAccess = {
  __typename?: 'SubscriptionProjectAccess';
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  role?: Maybe<ProjectAccessRoleEnum>;
  sequence: Scalars['BigInt']['output'];
  teamId?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectEntityChangedEvent = EntityChangedEvent & {
  __typename?: 'ProjectEntityChangedEvent';
  changeType: EntityChangeType;
  eventTime: Scalars['String']['output'];
  projectEntity: SubscriptionProject;
  projectId: Scalars['String']['output'];
};

export type SubscriptionProject = BaseProject & {
  __typename?: 'SubscriptionProject';
  clientName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shareable?: Maybe<ProjectShareable>;
  shareableUrl?: Maybe<Scalars['String']['output']>;
  studioVersion: StudioProjectVersion;
  updatedAt: Scalars['String']['output'];
};

export type ChangeProjectTypeEvent = {
  __typename?: 'ChangeProjectTypeEvent';
  fromType: ProjectTypeEnum;
  projectId: Scalars['String']['output'];
  toType: ProjectTypeEnum;
  uniqueCacheUserIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type FolderHierarchy = {
  __typename?: 'FolderHierarchy';
  children: Array<Folder>;
  parent: Folder;
};

export type TutorialCategory = {
  __typename?: 'TutorialCategory';
  icon: TutorialIcon;
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  type: TutorialType;
};

export type TutorialIcon = {
  __typename?: 'TutorialIcon';
  color?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type UserSubscriptionUndoCancellationInputs = {
  subscriptionId: Scalars['ID']['input'];
};
